:root{

    // selection text
    --selection-background : #104e94;
    --selection-text : #fff;

    // scroll bar color
    --scrool-background : #fff;
    --scrool-bar : #d5e3ec;

    // general coloration
    --primary-color : #104e94;
    --primary-color-contrast : #0b3a6e;
    --white-color : #fff;

    --tran-02: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;

    // body background
    --body-background : #fff;
    --body-font-color : #121c42;
    --body-font-color-saturate : #121c42;

    // login
    --login-background : #f7fafb;
    --login-form-background : #ffffff8f;
    --login-form-border : #bfbfbf;
    --login-form-shadow : #b9b9b980;
    --login-form-error : #dc3545;
    --login-form-icon : #bfbfbf;
    --login-form-placeholder : #bfbfbf;

    --add-btn-background : #104e94da;

}

.light{

    // selection text
    --selection-background : #104e94;
    --selection-text : #fff;

    // scroll bar color
    --scrool-background : #fff;
    --scrool-bar : #d5e3ec;

    // general coloration
    --primary-color : #104e94;
    --primary-color-contrast : #0b3a6e;
    --white-color : #fff;

    // login
    --login-background : #f7fafb;
    --login-form-background : #ffffff8f;
    --login-form-border : #bfbfbf;
    --login-form-shadow : #b9b9b980;
    --login-form-error : #dc3545;
    --login-form-icon : #bfbfbf;
    --login-form-placeholder : #bfbfbf;


    --add-btn-background : #104e94da;
}
