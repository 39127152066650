@media only screen and (max-width: 450px){
    .table_drop_action .dropdown ul {
        flex-wrap: wrap;
        gap: 10px !important;
    }
    li {
    width: 100%;

}
.pic{
    display: none;
}
}
@media only screen and (max-width: 1080px) {
    .pic{
        display: none;
    }

    .form-content div:has(.field), .modal__body div:has(.field){
        width: 100% !important;
    }
    .group_input .field, .form-content .field, .modal__body .field{
        margin-bottom: 20px !important;
    }

    .sidebar.close .nav-links li.showMenu .sub-menu:not(.blank){
        //display: none !important;
        position: absolute !important;
    }

    .link__table{
        width: 50%;
        margin-left: 50px;
    }
    .home-section .stats-countainer .stat-item {
        width: 100% !important;
    }
    .d-flex{
        width: 100% !important;
        flex-wrap: wrap;
        gap: 15px;
    }
    .chartJS{
        border-bottom: 1px solid #e2e2e2;
        border-right: none;
        width: 100% !important;
    }
    .js__chart{
        width: 100% !important;
        padding-right: 10px;
    }
    .wrapper {
        flex-direction: row;
    }
    .table-list thead{
        display: none;
    }
    .table-list, .table-list tbody,.table-list tr, .table-list td{
        display: block;
        width: 100% !important;

    }
    .table-list tr{
        //margin-bottom: 15px !important;
        border-bottom: 1px solid #e2e2e2;
    }
    .table-list table tbody tr td{
        min-height: 48px;
        border-bottom: 1px solid #e2e2e2;

        &.table_drop_action{
            display: flex;
            align-items: center;
        }

        &:last-of-type{
            border-bottom: none;
        }
    }
    .table-list td{
        text-align: right;
        padding-left: 21% !important;
        position: relative;

    }
    .table-list td::before{
        max-height: 2.3em;
        line-height: 3.2em;
        top: 0;
        padding: 0 1em;
        content: attr(data-label);
        position: absolute;
        left: 0;
        font-weight: bold;
        text-align: left;
        min-height: 48px;
        border-right: 1px solid #e2e2e2;
        margin: 0;
        width: 20%;
        overflow: hidden;
        text-overflow: ellipsis;


    }
    .table_drop_action .icon{
        display: none;
    }
    .table_drop_action .dropdown{
        visibility: visible !important;
        opacity: 1 !important;
        position: relative !important;
        background: none !important;
        border: none !important;
    }
    .table-list table tbody tr .table_drop_action .dropdown{
        right: 0;
    }
    .table_drop_action .dropdown ul {
        display: flex;
        //gap: 20px !important;
        flex-direction: row;
        background: none;

        //margin-bottom: 15px !important;
        //margin-top: 15px !important;

       li {
           border: 1px solid #e2e2e2 !important;
           background: #fff;
           //box-shadow: 0 0 5px #e2e2e2;

           &:first-of-type{
               border-radius: 4px 0 0 4px;
           }
           &:last-of-type{
               border-radius: 0 4px 4px 0;
           }
       }
        a {
            background: #fff;
            padding: 10px 20px 10px 15px;
            text-decoration: none;
            color: var(--body-font-color);


            &:hover {
                background-color: rgba(16, 78, 148, 0.15);
                color: var(--primary-color);
                transition: background-color .3s ease;
            }
        }
    }
.reglement_numeraire{
    width: 100% !important;
    tr{
        text-align: center;
    }
}
#deuxBtn{
    display: flex;

    width: 100% !important;
    margin-bottom: 15px !important;
}
    .form_content{
        width: 100% !important;
        flex-wrap: nowrap;

    }
    .fullw{
        width: 100% !important;
    }
    .group_input{
        flex-wrap: wrap;
    }
    #column{
        flex-direction: column-reverse;
    }
    .mt-25, .mt-40{
        width: 100% !important;
    }
    .tabs__radio{
        width: 100% !important;
    }
    #JS_GenerateForm{
        width: 250px !important;
    }
    .w-6x,.w-7x, .w-10x, .w-8x, .w-5x, .w-x22 {
        max-width: 90vw !important;
    }
    .tx{
        margin-bottom:5px !important ;
    }

    .link__table{
        display: block;
        justify-content: center;
    }
    #prt i{
        align-items: center;
    }
    .ul{
        margin-left: -70px;
        box-shadow: 0 0 5px #e2e2e2;
        &:hover{
            background-color: rgba(16, 78, 148, 0.15);
            color: var(--primary-color);
            transition: background-color .3s ease;
        }
    }
    .li{
        margin-left: -18px;
        box-shadow: 0 0 5px #e2e2e2;
        &:hover{
            background-color: rgba(16, 78, 148, 0.15);
            color: var(--primary-color);
            transition: background-color .3s ease;
        }
        .Mail{
            padding-left: -40% !important;
        }
    }
    .fullw-x{
        width: 95% !important;
        margin-left: 10px !important;
    }
    .ligne{
        display: flex;
        gap: 10px !important;
    }
    .xt{
        margin-left: -10% !important;
    }
    .mr{
        margin-bottom: 10px !important;
    }


.modal__container{
    width: 95vw !important;
}

}







//
//@media(max-width: 480px){
//    .home-section .stats-countainer{
//        flex-direction: row;
//        flex-wrap: wrap;
//        align-items: center;
//        align-content: center;
//        justify-content: space-between;
//        margin: 20px 0;
//        background: #000;
//
//    }
//    .home-section .stats-countainer .stat-item{
//        width: 50%;
//        height: 100px;
//        padding: 10px 20px;
//        border-radius: 5px;
//        display: flex;
//        align-items: center;
//        align-content: center;
//        transition: .5s ease;
//        border: 1px solid #e2e2e282;
//        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.04);
//    }
//    .d-flex .mt-40{
//        display: flex;
//        flex-direction: column;
//    }
//}
@media only screen and (max-height: 700px) {
    #by_profisc {
        bottom: -2em !important;
    }
}

@media only screen and (max-height: 620px) {
    #by_profisc {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
}
