.checkbox, .radio{line-height:0;}
.checkbox .checkbox-container{display:inline-block;position:relative;}
.checkbox .checkbox-container input{cursor:pointer;opacity: 0;position: absolute;margin: 0;width: 100%;height: 100%;left:0;z-index: 1;}
.checkbox .checkbox-container input:hover ~ .checkbox-checkmark::after{display:block;opacity:0.5;}
.checkbox .checkbox-container input:checked ~ .checkbox-checkmark{background:var(--primary-color);border-color:transparent;}
.checkbox .checkbox-container input:checked ~ .checkbox-checkmark::after{display:block;opacity:1;border-color:white;}
.checkbox .checkbox-container .checkbox-checkmark{background-color: #eee;border: 1px #ddd solid;}
.checkbox .checkbox-container .checkbox-checkmark::after{box-sizing: inherit;cursor:pointer;display:none;position: absolute;content: "";border: solid #bebebe;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}
.checkbox .checkbox-container .checkbox-checkmark:hover::after{display:block;}
.checkbox label{display:inline;padding-left:0.5rem;vertical-align: super;}
.checkbox label:hover ~ .checkbox-checkmark::after{display:block;opacity:0.5;}
/* default (small) */
.checkbox label{font-size:1em;}
.checkbox .checkbox-container .checkbox-checkmark{width:26px;height:26px;}
.checkbox .checkbox-container .checkbox-checkmark::after{height:15.6px;width:7.8px;left:9.1px;top:3px;border-width: 0 2px 4px 0;}
/* tiny */
.checkbox.tiny label{font-size:0.9em;}
.checkbox.tiny .checkbox-container .checkbox-checkmark{width:20px;height:20px;}
.checkbox.tiny .checkbox-container .checkbox-checkmark:after{height:12px;width:6px;left:7px;top:3px;border-width:0 2px 2px 0;}
/* small */
.checkbox.small label{font-size:1em;}
.checkbox.small .checkbox-container .checkbox-checkmark{width:26px;height:26px;}
.checkbox.small .checkbox-container .checkbox-checkmark::after{height:15.6px;width:7.8px;left:9.1px;top:3px;border-width: 0 2px 4px 0;}
/* medium */
.checkbox.medium label{font-size:1.25em;}
.checkbox.medium .checkbox-container .checkbox-checkmark{width:34px;height:34px;}
.checkbox.medium .checkbox-container .checkbox-checkmark::after{height:20.4px;width:10.2px;left:11.9px;top:4px;border-width: 0 3px 5px 0;}
/* large */
.checkbox.large label{font-size:1.5em;}
.checkbox.large .checkbox-container .checkbox-checkmark{width:40px;height:40px;}
.checkbox.large .checkbox-container .checkbox-checkmark::after{height:24px;width:12px;left:14px;top:5px;border-width: 0 3px 6px 0;}
/* disabled */
.checkbox.disabled .checkbox-container input{display:none;}
.checkbox.disabled .checkbox-container .checkbox-checkmark{cursor:not-allowed;}
.checkbox.disabled .checkbox-container .checkbox-checkmark{background: #ddd;border: 1px #ccc solid;}
.checkbox.disabled .checkbox-container .checkbox-checkmark::after{display:block;border-color:#bbb;opacity:0.5;cursor:not-allowed;}
.checkbox.disabled .checkbox-container input:checked ~ .checkbox-checkmark{background:#ddd;}
/* rounded */
.checkbox.rounded-3  .checkbox-container .checkbox-checkmark{border-radius:3px;}
.checkbox.rounded-6  .checkbox-container .checkbox-checkmark{border-radius:6px;}
.checkbox.rounded-8  .checkbox-container .checkbox-checkmark{border-radius:8px;}
.checkbox.rounded-10 .checkbox-container .checkbox-checkmark{border-radius:10px;}
.checkbox.rounded-16 .checkbox-container .checkbox-checkmark{border-radius:16px;}
.checkbox.rounded-22 .checkbox-container .checkbox-checkmark{border-radius:22px;}

.radio .radio-container{display:inline-block;position:relative;}
.radio .radio-container input{cursor:pointer;opacity: 0;position: absolute;margin: 0;width: 100%;height: 100%;left:0;z-index: 1;}
.radio .radio-container input:hover ~ .radio-checkmark::after{display:block;opacity:0.5;background:#bebebe;}
.radio .radio-container input:checked ~ .radio-checkmark{background:var(--primary-color);border-color:transparent;}
.radio .radio-container input:checked ~ .radio-checkmark::after{display:block;opacity:1;background:white;}
.radio .radio-container .radio-checkmark{background-color: #eee;border: 1px #ddd solid;border-radius:50%;}
.radio .radio-container .radio-checkmark::after{box-sizing: inherit;cursor:pointer;display:none;position: absolute;content: "";border: solid #bebebe;}
.radio .radio-container .radio-checkmark:hover::after{display:block;}
.radio label{display:inline;padding-left:0.5rem;vertical-align: super;}
.radio label:hover ~ .radio-checkmark::after{display:block;opacity:0.5;}
/* default (small) */
.radio label{font-size:1em;}
.radio .radio-container .radio-checkmark{width:26px;height:26px;}
.radio .radio-container .radio-checkmark::after{height:16px;width:16px;left:5px;top:5px;border:0;border-radius:50%;}
/* tiny */
.radio.tiny label{font-size:0.9em;}
.radio.tiny .radio-container .radio-checkmark{width:20px;height:20px;}
.radio.tiny .radio-container .radio-checkmark:after{height:12px;width:12px;left:4px;top:4px;}
/* small */
.radio.small label{font-size:1em;}
.radio.small .radio-container .radio-checkmark{width:26px;height:26px;}
.radio.small .radio-container .radio-checkmark::after{height:16px;width:16px;left:5px;top:5px;border:0;border-radius:50%;}
/* medium */
.radio.medium label{font-size:1.25em;}
.radio.medium .radio-container .radio-checkmark{width:34px;height:34px;}
.radio.medium .radio-container .radio-checkmark::after{height:20px;width:20px;left:7px;top:7px;}
/* large */
.radio.large label{font-size:1.5em;}
.radio.large .radio-container .radio-checkmark{width:40px;height:40px;}
.radio.large .radio-container .radio-checkmark::after{height:24px;width:24px;left:8px;top:8px;}
/* disabled */
.radio.disabled .radio-container input{display:none;}
.radio.disabled .radio-container .radio-checkmark{cursor:not-allowed;}
.radio.disabled .radio-container .radio-checkmark{background: #ddd;border: 1px #ccc solid;}
.radio.disabled .radio-container .radio-checkmark::after{display:block;background:#bbb;opacity:0.5;cursor:not-allowed;}
.radio.disabled .radio-container input:checked ~ .radio-checkmark{background:#ddd;}
